body {
  background-color: black;
  color: white;
  font-family: Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 601px) {
  body {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  body {
    font-size: 12px;
  }
}
