
.App-logo {
  max-width: 40vw;
}

.App-desc {
  margin-left: 3%;
}

@media screen and (min-width: 601px) {
  .App-desc, .telegram, .footer {
    max-width: 60vw;
  }
  .submit {
    font-size: 14px;
    font-family: Helvetica;
    width: 100px;
    height: 35px;
  }

  #unsubscribe {
    width: 130px;
  }

  .email {
    font-size: 18px;
    font-family: Helvetica;
    width: 350px;
    height: 25px;
  }
  .error {
    font-size: 16px;
    font-family: Helvetica;
  }
  a:link, a:visited {
    font-size: 14px;
    font-family: Helvetica;
    border: 2px solid white;
    padding: 5px 5px;
    margin-left: 15px;
  }
  .sources {
    font-size: 12px;
  }
  .info {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .App-desc, .telegram, .footer {
    max-width: 90vw;
    font-size: 12px;
  }
  .info {
    max-width: 70vw;
  }

  .email, .submit {
    font-size: 12px;
    font-family: Helvetica;
  }
  .submit {
    font-size: 10px;
    font-family: Helvetica;
    width: 80px;
    height: 23px;
  }

  #unsubscribe {
    width: 90px;
  }

  .email {
    font-size: 12px;
    font-family: Helvetica;
    width: 200px;
    height: 15px;
  }
  .error {
    font-size: 10px;
    font-family: Helvetica;
  }
  a:link, a:visited {
    font-size: 10px;
    font-family: Helvetica;
    border: 1px solid white;
    padding: 10px 10px;
    padding: 5px 5px;
    margin-left: 10px;
  }
  .sources {
    font-size: 9px;
  }
  .info {
    font-size: 10px;
  }
}

#typewriter1 {
  height: 90px;
}

#form-subscription, .telegram, .footer {
  margin-left: 3%;
}

.input, .button {
  display: inline-block;
  height: 30px;
}

.button {
  border-color: white;
}

.submit {
  color: white;
  background-color: black;
  margin-left: 5px;
  margin-top: 5px;
  border-radius: 3px;
  border-color: white;
}

.submit:hover {
  color: black;
  background-color: white;
}

.telegram {
  margin-top: 3%;
}

#telegram_text_container, #telegram_link_container {
  display: inline-block;
}

a:link, a:visited {
  background-color: black;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 3px;
}

a:hover, a:active {
  background-color: white;
  color: black;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer {
  margin-top: 7%;
}

.info {
  margin-top: 3%;
}

.error {
  color: crimson;
}

.alert-inner {
  margin-top: 0.5%;
}

.check {
  margin: 1%;
}

#cities {
  margin-top: 1.5%;
}

#geneva, #lausanne, #zurich, #basel, #lugano {
  margin-right: 1%;
}
